import React, { useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";

const ImageUpload: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    setFiles(selectedFiles);

    const filePreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setPreviews(filePreviews);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (files.length === 0) {
      setUploadStatus("No files selected");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("images[]", file);
    });

    try {
      const response = await axios.post(`${apiUrl}/upload.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === "success") {
        setUploadStatus("Files successfully uploaded.");
        // Clear the files and previews
        setFiles([]);
        setPreviews([]);
      } else {
        setUploadStatus(`Upload failed: ${response.data.message}`);
      }
    } catch (error) {
      setUploadStatus("Error uploading the images.");
      console.error("Error uploading the images", error);
    }
  };

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "240px",
        marginTop: "20px",
        fontFamily: "Montserrat",
      }}
    >
      <div className="container mt-5 ">
        <Typography
          variant="h5"
          color="#2C3E50"
          style={{ marginBottom: "30px", fontFamily: "Montserrat", fontWeight: "600" }}
        >
          Upload Image
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="form-group gap-4">
            <input
              style={{ fontFamily: "Montserrat" }}
              type="file"
              className="form-control mb-4"
              onChange={handleFileChange}
              multiple
            />
            <button
              type="submit"
              className="btn btn-primary"
              style={{ fontFamily: "Montserrat" }}
            >
              Upload
            </button>
          </div>
          <div className="mt-3">
            {previews.map((preview, index) => (
              <img
                key={index}
                src={preview}
                alt={`Preview ${index + 1}`}
                width="200"
                className="me-2 mb-2"
                style={{fontFamily: "Montserrat" }}
              />
            ))}
          </div>
        </form>
        {uploadStatus && (
          <div className="mt-3">
            <p style={{fontFamily: "Montserrat" }} >{uploadStatus}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
