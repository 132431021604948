import React, { useState, useEffect, ReactNode, MouseEvent, CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

interface RippleType {
  x: number;
  y: number;
  id: number;
}

// Extend the basic HTML button props
interface RippleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  sx?: SxProps<Theme>;
  // Add any other framework-specific props you need
}

const RippleButton: React.FC<RippleButtonProps> = ({ 
  children, 
  onClick,
  className = '',
  style,
  sx = {},
  ...props // Spread remaining props to button
}) => {
  const [ripples, setRipples] = useState<RippleType[]>([]);

  useEffect(() => {
    const timeouts = ripples.map((_, i) => {
      return setTimeout(() => {
        setRipples(prev => prev.filter((_, idx) => idx !== 0));
      }, 1000);
    });

    return () => timeouts.forEach(t => clearTimeout(t));
  }, [ripples]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    setRipples(prev => [...prev, { x, y, id: Date.now() }]);
    
    if (onClick) onClick(e);
  };

  // Merge provided styles with required ripple styles
  const mergedStyles: CSSProperties = {
    position: 'relative',
    overflow: 'hidden',
    ...style // Spread any additional styles passed to the component
  };

  return (
    <button
      className={`relative overflow-hidden ${className}`}
      onClick={handleClick}
      style={mergedStyles}
      {...props} // Spread remaining props
    >
      {/* Ripple container */}
      <div className="absolute inset-0 pointer-events-none">
        {ripples.map(({ x, y, id }) => (
          <span
            key={id}
            className="absolute rounded-full bg-white bg-opacity-30
                       transform -translate-x-1/2 -translate-y-1/2
                       animate-ripple"
            style={{
              left: `${x}px`,
              top: `${y}px`,
              width: '200px',
              height: '200px',
            }}
          />
        ))}
      </div>
      
      {/* Button content */}
      <span className="relative z-10">{children}</span>
    </button>
  );
};

// Define the ripple animation
const style = document.createElement('style');
style.textContent = `
  @keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0.6;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }
  
  .animate-ripple {
    animation: ripple 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
`;
document.head.appendChild(style);

export default RippleButton;