import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../../firebaseConfig";
import CryptoJS from "crypto-js";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { ReactComponent as RegisterdUser } from "../../../assets/user.svg";
import { ReactComponent as SubUser } from "../../../assets/icons/Approved.svg";
import { ReactComponent as Tickets } from "../../../assets/icons/help.svg";
import { ReactComponent as ApprovedDriver } from "../../../assets/icons/driverApp.svg";
import { ReactComponent as PendingDriver } from "../../../assets/icons/person.svg";
import { ReactComponent as DistanceCovered } from "../../../assets/icons/direction.svg";
import { ReactComponent as Revenue } from "../../../assets/icons/dollar.svg";
import { ReactComponent as Journeys } from "../../../assets/icons/distance.svg";
import { ReactComponent as Distance } from "../../../assets/icons/car.svg";
import DatabaseApi from "../../../services/database_api";
import { CircularProgress } from "@mui/material";

// Add the decryption utility
const decryptApiKey = (encryptedKey: string): string => {
  const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "";
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedKey, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return "";
  }
};

const mapContainerStyle = {
  width: "100%",
  height: "550px",
  borderRadius: "12px",
  marginBottom: "24px",
};

interface TripStatus {
  completed: "success";
  ongoing: "warning";
  upcoming: "info";
}

interface Trip {
  id: number;
  user_name: string;
  driver_name?: string;
  pickup_location: string;
  destination: string;
  pickup_time: string;
  drop_time?: string;
  user_vehicle_type?: string;
  status: keyof TripStatus;
  ph_no: string;
  license_plate_number_user?: string;
  driver_id?: string;
}
interface PlanDetails {
  id: number;
  plan_name: string;
  duration: string;
  price: string;
  distance_covered_per_journey: string;
  no_of_journeys_included: string;
  rate_per_extra_km: string;
  accident_recovery_fee: string;
  revine_ditch_precipice_fee: string;
  banner: string;
  created_at: string;
  updated_at: string;
}

interface UserProfile {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  country_code: string;
  vehicle_type: string;
  vehicle_color: string;
  license_plate_number: string;
  profile_image: string;
  account_status: string;
  fcm_token: string;
  plan_id: string;
  plan_expiry_date: string;
  plan_count: string;
  start_plan_date: string;
  created_at: string;
  updated_at: string;
  planDetails: PlanDetails;
}
interface ApiResponses {
  status: string;
  subscribedUsers: UserProfile[]; // Changed from data to subscribedUsers
}

interface Location {
  id: string;
  lat: number;
  lng: number;
  driverName?: string;
  status?: string;
  tripId: number;
  timeStamp?: number;
}
interface FirebaseDriver {
  lat: number;
  lng: number;
  timeStamp: number;
}
interface FirebaseData {
  [key: string]: FirebaseDriver;
}

// Add this to your existing interfaces
interface MapLocation {
  lat: number;
  lng: number;
  title: string;
}
// Updated interfaces to match API response structure
interface ApiResponse<T> {
  status: string;
  message: string;
  data: T;
}
// Types for our API responses
interface DashboardStats {
  RegisterUsers?: number;
  SubscribedUsers?: number;
  "Pending Support Ticket"?: number;
  "Apporved Drivers"?: number;
  "Pending Drivers"?: number;
  "Total Distance Covered"?: number;
  "Total Revenue from Users"?: number;
  "Total Journeys"?: number;
  "Average Trip Distance"?: number;
}
interface SubscriptionSummary {
  name: string;
  email: string;
  plan: string;
  startdate: string;
  enddate: string;
}
interface PerformingDriver {
  name: string;
  complete_ride: number;
  earnings: number;
  distance_covered: number;
  activity_days: number;
}
interface StatCardProps {
  title: string;
  value: string;
  icon: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>; // Flexible type
}
const StatCard: React.FC<StatCardProps> = ({ title, value, icon }) => {
  // condtionaly render color value of card
  let valueColor = "#345C72";
  if (title === "Total Revenue from Users") {
    valueColor = "#1FA541";
  } else if (title === "Pending Support Tickets") {
    valueColor = "#C63708";
  }

  // Render the icon conditionally as an <img> tag or as an SVG component
  const renderIcon = () =>
    typeof icon === "string" ? (
      <img
        src={icon}
        alt={title}
        style={{ width: "30px", height: "30px", marginBottom: "10px" }}
      />
    ) : (
      React.createElement(icon, {
        style: { width: "30px", height: "30px", marginBottom: "10px" },
      })
    );

  return (
    <Card
      sx={{
        backgroundColor: "#F0F3FA",
        boxShadow: "none",
        outline: "none",
        fontFamily: "Montserrat, sans-serif",
      }}
    >
      <CardContent>
        <div style={{ float: "right", fontFamily: "Montserrat" }}>
          {renderIcon()}
        </div>
        <Typography
          variant="h6"
          fontWeight="medium"
          color="#345C72"
          gutterBottom
          style={{ fontFamily: "Montserrat" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          fontWeight="bold"
          color={valueColor}
          component="div"
          style={{ fontFamily: "Montserrat" }}
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Dashboard: React.FC = () => {
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [dashboardData, setDashboardData] = useState<DashboardStats | null>(
    null
  );
  const [subscriptionData, setSubscriptionData] = useState<
    SubscriptionSummary[]
  >([]);
  const [driversData, setDriversData] = useState<PerformingDriver[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // const [mapLocations] = useState<MapLocation[]>(defaultMapLocations);
  const [mapsApiKey, setMapsApiKey] = useState<string>("");
  const [center, setCenter] = useState({
    lat: 18.0361742,
    lng: -76.7993185,
  });

  const fetchDashboardData = async () => {
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.getUserSubscription();

    try {
      setLoading(true);
      const response = await axios.get<ApiResponses>(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "true") {
        // Access subscribedUsers array instead of data
        const userData = response.data.subscribedUsers || [];
        setUserProfiles(userData);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError("Failed to fetch dashboard data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  useEffect(() => {
    const fetchTrips = async (page: number = 1, search: string = "") => {
      const apiUrl = DatabaseApi.getTripOverViews(page, search);
      const token = localStorage.getItem("authToken");

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data?.data && Array.isArray(response.data.data)) {
          setTrips(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };
    fetchTrips();
  }, []);
  const [trips, setTrips] = useState<Trip[]>([]);

  // Add this debugging useEffect after the trips are fetched
  useEffect(() => {
    // Debug trips data
    console.log("All trips:", trips);

    const upcomingTrips = trips.filter((trip) => trip.status === "upcoming");
    console.log("Number of upcoming trips:", upcomingTrips.length);
    console.log("Upcoming trips:", upcomingTrips);
  }, [trips]);

  const isWithinLastHour = (timestamp: number): boolean => {
    const oneHourAgo = Date.now() - 60 * 60 * 1000; // Current time minus 1 hour in milliseconds
    return timestamp >= oneHourAgo;
  };

  useEffect(() => {
    const driversRef = ref(database, "drivers");

    const listener = onValue(driversRef, (snapshot) => {
      const firebaseData: FirebaseData = snapshot.val() || {};
      console.log("Firebase data:", firebaseData);

      // Get all active trips (both ongoing and upcoming)
      const activeTrips = trips.filter(
        (trip) => trip.status === "ongoing" || trip.status === "upcoming"
      );
      console.log("Active trips:", activeTrips);

      // Filter and create locations array combining Firebase data and trip data
      const driverLocations = activeTrips
        .map((trip) => {
          const firebaseDriver = firebaseData[trip.driver_id || ""];

          // Skip if no driver data or timestamp is not within last hour
          if (!firebaseDriver || !isWithinLastHour(firebaseDriver.timeStamp)) {
            console.log(
              `Driver ${trip.driver_id} filtered out due to timestamp or missing data`
            );
            return null;
          }

          console.log(`Processing trip ${trip.id}:`, {
            tripData: trip,
            firebaseDriver: firebaseDriver,
            timeStamp: new Date(firebaseDriver.timeStamp).toLocaleString(),
          });

          // Extract coordinates from pickup_location if needed
          let pickupCoords = { lat: 0, lng: 0 };
          if (trip.pickup_location && trip.pickup_location.includes(",")) {
            const coordParts = trip.pickup_location.split(",");
            if (coordParts.length >= 2) {
              const lat = parseFloat(coordParts[coordParts.length - 2]);
              const lng = parseFloat(coordParts[coordParts.length - 1]);
              if (!isNaN(lat) && !isNaN(lng)) {
                pickupCoords = { lat, lng };
              }
            }
          }

          // Create location object with timestamp
          const location: Location = {
            id: trip.driver_id || "",
            lat: firebaseDriver.lat || pickupCoords.lat,
            lng: firebaseDriver.lng || pickupCoords.lng,
            driverName: trip.driver_name || "Unknown Driver",
            status: trip.status,
            tripId: trip.id,
            timeStamp: firebaseDriver.timeStamp,
          };

          return location;
        })
        .filter((location): location is Location => {
          if (!location) return false;

          // Filter out invalid coordinates
          const isValid = location.lat !== 0 && location.lng !== 0;
          if (!isValid) {
            console.log(
              `Filtered out location for trip ${location.tripId} due to invalid coordinates`
            );
            return false;
          }
          return true;
        });

      console.log("Final filtered driver locations:", driverLocations);
      setLocations(driverLocations);
    });

    return () => listener();
  }, [trips]);

  useEffect(() => {
    const initializeMapsKey = () => {
      const encryptedKey =
        process.env.REACT_APP_ENCRYPTED_GOOGLE_MAPS_KEY || "";
      console.log("Env variables present:", {
        hasEncryptionKey: !!process.env.REACT_APP_ENCRYPTION_KEY,
        hasEncryptedKey: !!encryptedKey,
      });

      if (!encryptedKey) {
        setError("Missing encrypted Maps API key");
        return;
      }

      const decrypted = decryptApiKey(encryptedKey);
      // Remove this in production

      // Check for common issues
      if (!/^AIza/.test(decrypted)) {
        setError("Invalid API key format - should start with AIza");
        return;
      }

      setMapsApiKey(decrypted);
    };

    initializeMapsKey();
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      console.log("Fetching data...");
      const apiUrl = DatabaseApi.getAdminDashboardUrl();
      const apiUrl2 = DatabaseApi.getSubscriptionSummaryUrl();
      const apiUrl3 = DatabaseApi.getPerformingDriversUrl();
      const token = localStorage.getItem("authToken");
      try {
        setLoading(true);
        // Fetching admin dashboard data
        const adminResponse = await axios.get<ApiResponse<DashboardStats>>(
          apiUrl,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setDashboardData(adminResponse.data.data || {});

        // Fetching subscription summary data
        const subscriptionResponse = await axios.get<
          ApiResponse<SubscriptionSummary[]>
        >(apiUrl2, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setSubscriptionData(subscriptionResponse.data.data || []);

        // Fetching performing drivers data
        const driversResponse = await axios.get<
          ApiResponse<PerformingDriver[]>
        >(apiUrl3, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setDriversData(driversResponse.data.data || []);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError("Failed to load dashboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const getSafeValue = (value: number | undefined): string => {
    return value?.toString() || "0";
  };

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress size={40} />
      </div>
    );

  if (error) return <Typography color="error">{error}</Typography>;
  if (!dashboardData) return <Typography>No data available</Typography>;
  if (!mapsApiKey)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress size={40} />
      </div>
    );

  return (
    <div
      className="p-6 bg-white min-h-screen font-montserrat"
      style={{ maxWidth: "90%", marginLeft: "245px", marginTop: "70px" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        color="#2C3E50"
        style={{ fontFamily: "Montserrat", fontWeight: "600" }}
      >
        Dashboard
      </Typography>

      {/* Map Section */}
      <LoadScript googleMapsApiKey={mapsApiKey}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          options={{
            styles: [
              {
                featureType: "all",
                elementType: "geometry",
                stylers: [{ color: "#F0F3FA" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#E3E6ED" }],
              },
            ],
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true,
          }}
        >
          {locations.map((driver) => (
            <Marker
              key={`${driver.id}-${driver.tripId}`}
              position={{ lat: driver.lat, lng: driver.lng }}
              title={`Driver Name: ${driver.driverName} \nStatus: ${driver.status}`}
              icon={{
                url:
                  driver.status === "ongoing"
                    ? "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                    : "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          ))}
        </GoogleMap>
      </LoadScript>

      <Typography
        variant="h5"
        color="#2C3E50"
        gutterBottom
        style={{
          marginTop: "60px",
          fontFamily: "Montserrat",
          fontWeight: "500",
        }}
      >
        Overview
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Registered Users"
            value={getSafeValue(dashboardData.RegisterUsers)}
            icon={RegisterdUser}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Subscribed Users"
            value={getSafeValue(dashboardData.SubscribedUsers)}
            icon={SubUser}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Pending Support Tickets"
            value={getSafeValue(dashboardData["Pending Support Ticket"])}
            icon={Tickets}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Approved Drivers"
            value={getSafeValue(dashboardData["Apporved Drivers"])}
            icon={ApprovedDriver}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Pending Driver Accounts"
            value={getSafeValue(dashboardData["Pending Drivers"])}
            icon={PendingDriver}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Distance Covered"
            value={getSafeValue(dashboardData["Total Distance Covered"])}
            icon={DistanceCovered}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Revenue from Users"
            value={getSafeValue(dashboardData["Total Revenue from Users"])}
            icon={Revenue}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Journeys"
            value={getSafeValue(dashboardData["Total Journeys"])}
            icon={Journeys}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Average Trip Distance"
            value={getSafeValue(dashboardData["Average Trip Distance"])}
            icon={Distance}
          />
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        color="#2C3E50"
        gutterBottom
        style={{
          marginTop: "60px",
          fontFamily: "Montserrat",
          fontWeight: "500",
        }}
      >
        Subscription Summary
      </Typography>
      <TableContainer component={Paper} elevation={0} color="#2C3E50">
        <Table>
          <TableHead sx={{ backgroundColor: "#F0F3FA" }}>
            <TableRow color="#2C3E50">
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
                Name
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
                Email
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
                Plan
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
                Start Date
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
                End Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Error: {error}
                </TableCell>
              </TableRow>
            ) : userProfiles?.length > 0 ? (
              userProfiles.map((profile) => (
                <TableRow key={profile.id}>
                  <TableCell>{`${profile.first_name || ""} ${
                    profile.last_name || ""
                  }`}</TableCell>
                  <TableCell>{profile.email || ""}</TableCell>
                  <TableCell>{profile.planDetails?.plan_name || ""}</TableCell>
                  <TableCell>
                    {profile.start_plan_date
                      ? new Intl.DateTimeFormat("en-GB")
                          .format(new Date(profile.start_plan_date))
                          .replace(/\//g, "-")
                      : ""}
                  </TableCell>
                  <TableCell>
                    {profile.plan_expiry_date
                      ? new Intl.DateTimeFormat("en-GB")
                          .format(new Date(profile.plan_expiry_date))
                          .replace(/\//g, "-")
                      : ""}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant="h5"
        color="#2C3E50"
        gutterBottom
        style={{
          marginTop: "60px",
          fontFamily: "Montserrat",
          fontWeight: "500",
        }}
      >
        Top Performing Drivers
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead
            sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
          >
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                color="#2C3E50"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                color="#2C3E50"
              >
                Completed Rides
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                color="#2C3E50"
              >
                Earnings ($)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                color="#2C3E50"
              >
                Distance Covered (km)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                color="#2C3E50"
              >
                Active Days
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {driversData.map((driver, index) => (
              <TableRow key={index}>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {driver.name}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {driver.complete_ride}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {driver.earnings}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {driver.distance_covered}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {driver.activity_days}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Dashboard;
