import React, { useEffect, useState, useMemo } from "react";
import debounce from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/Arrow-down.svg";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Fill 1.svg";
import { Gear, X } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Alert,
  Snackbar,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
} from "@mui/material";
import Pagination from "../../PaginationComp/Paginations";
import DatabaseApi from "../../../../services/database_api";
import axios from "axios";
import { CircularProgress } from "@mui/material";

interface BaseDriverDetail {
  ravine_ditch_count: number;
  driver_total: string;
  paid_date_time: string;
  paid_amount?: string;
  pending_amount?: string;

  driver_id: number;
  driver_name: string;
  total_distance: string;
  total_hill_km: string;
  accident_recovery_count: number;
  complete_trip_count: number;
  charge_from_user: string;
}

interface PaidDriverDetail extends BaseDriverDetail {
  paid_amount: string;
  paid_charge_from_user: string;
  paid_date_time: string;
}

interface PendingDriverDetail extends BaseDriverDetail {
  pending_amount: string;
  pending_charge_from_user: string;
}

type DriverDetail = PaidDriverDetail | PendingDriverDetail;

interface ApiResponse {
  data: any[];
  total: number;
  total_pages: number;
  current_page: string;
  per_page: number;

  status: string;
  message: string;
  total_amount: string;
  driver_details: DriverDetail[];
}

interface PlanData {
  rate_per_km: string;
  rate_per_hill_km: string;
  accident_recovery_rate: string;
  miscellaneous_rate: string;
}
const defaultContactDetails: PlanData = {
  rate_per_km: "",
  rate_per_hill_km: "",
  accident_recovery_rate: "",
  miscellaneous_rate: "",
};

const SupportTicket: React.FC = () => {
  const navigate = useNavigate();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [drivers, setDrivers] = useState<DriverDetail[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [PlanData, SetPlanData] = useState<PlanData>(defaultContactDetails);
  const [initialValues, setInitialValues] = useState<PlanData>(
    defaultContactDetails
  );
  const [formChanged, setFormChanged] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [activationStatus, setActivationStatus] = useState("Pending");
  const [openModal, setOpenModal] = useState(false);

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showError, setShowError] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<{
    name: string;
    pendingAmount: string;
    chargeFromUser: string;
  } | null>(null);

  const fetchDrivers = async (
    pageNumber: number,
    searchQuery: string,
    type: string
  ) => {
    const apiUrl = DatabaseApi.getdriverPayoutDetials(
      pageNumber,
      searchQuery,
      type
    );
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.post<ApiResponse>(
        apiUrl,
        { status: activationStatus.toLowerCase() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDrivers(response.data.driver_details || []);
      setTotalItems(response.data.total || response.data.driver_details.length);
      setTotalPages(
        Math.ceil(
          (response.data.total || response.data.driver_details.length) /
            itemsPerPage
        )
      );
    } catch (error) {
      console.error("Error fetching drivers data:", error);
      setDrivers([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    const timeoutId = setTimeout(() => {
      const sortType =
        sortOrder === "Earliest" ? "old" : sortOrder === "Latest" ? "new" : "";
      fetchDrivers(1, searchQuery, sortType);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchQuery, sortOrder]);

  useEffect(() => {
    if (!searchQuery) {
      const sortType =
        sortOrder === "Earliest" ? "old" : sortOrder === "Latest" ? "new" : "";
      fetchDrivers(currentPage, "", sortType);
    }
  }, [currentPage, activationStatus, sortOrder]);

  const fetchPlanData = async () => {
    const apiUrl = DatabaseApi.getPayoutPlan();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data) {
        const fetchedData = response.data.data;

        const sanitizedData = {
          rate_per_km: fetchedData.rate_per_km || "",
          rate_per_hill_km: fetchedData.rate_per_hill_km || "",
          accident_recovery_rate: fetchedData.accident_recovery_rate || "",
          miscellaneous_rate: fetchedData.miscellaneous_rate || "",
        };
        SetPlanData(sanitizedData);
        setInitialValues(sanitizedData);
      } else {
        setError("No data available.");
      }
    } catch (error) {
      setError("Failed to fetch contact details. Please try again later.");
      console.error("Error fetching contact details:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPlanData();
  }, []);

  const saveChanges = async () => {
    const apiUrl = DatabaseApi.updatePayoutRates();
    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.post(
        apiUrl,
        {
          rate_per_km: PlanData.rate_per_km,
          rate_per_hill_km: PlanData.rate_per_hill_km,
          accident_recovery_rate: PlanData.accident_recovery_rate,
          miscellaneous_rate: "0",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setInitialValues(PlanData);
        setFormChanged(false);
        setShowSuccessSnackbar(true);
        return true;
      }
      return false;
    } catch (error) {
      setError("Failed to update payout rates. Please try again.");
      setTimeout(() => setError(null), 3000);
    }
  };

  const isPaidDriver = (driver: DriverDetail): driver is PaidDriverDetail => {
    return "paid_amount" in driver;
  };

  const renderTableCell = (driver: DriverDetail) => {
    if (activationStatus === "Paid" && isPaidDriver(driver)) {
      return (
        <TableCell style={{ fontFamily: "Montserrat" }}>
          {driver.paid_amount}
        </TableCell>
      );
    } else if (activationStatus === "Pending" && !isPaidDriver(driver)) {
      return (
        <TableCell style={{ fontFamily: "Montserrat" }}>
          {driver.pending_amount}
        </TableCell>
      );
    }
    return null;
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date
      .toLocaleString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(/\//g, "-")
      .replace(/am|pm/g, (match) => match.toUpperCase());
  };

  // row click model handel
  const handleRowClick = (driver: DriverDetail) => {
    if (activationStatus === "Pending" && "pending_amount" in driver) {
      setSelectedDriver({
        name: driver.driver_name,
        pendingAmount: driver.pending_amount || "0",
        chargeFromUser: driver.charge_from_user.toString(),
      });
    } else if (activationStatus === "Paid" && "paid_amount" in driver) {
      navigate(`/admin/driver-payouts/${driver.driver_id}`, {
        state: {
          driver: {
            driver_id: driver.driver_id,
            driver_name: driver.driver_name,
            total_distance: driver.total_distance,
            total_hill_km: driver.total_hill_km,
            accident_recovery_count: driver.accident_recovery_count,
            complete_trip_count: driver.complete_trip_count,
            charge_from_user: driver.charge_from_user,
            driver_total: driver.driver_total,
            amount_Paid: driver.paid_amount || "0",
            date_Of_Payment: formatDate(driver.paid_date_time),
            pending_Amount: "0",
          },
          name: driver.driver_name,
          amountPaid: driver.paid_amount || "0",
          dateOfPayment: formatDate(driver.paid_date_time),
        },
      });
    }
  };
  const handleCloseDriverModal = () => {
    setSelectedDriver(null);
  };

  const handleMarkAsPaid = async () => {
    if (!selectedDriver) return;
    const apiUrl = DatabaseApi.postMarkAsPaid();
    const token = localStorage.getItem("authToken");
    try {
      const driverId = drivers.find(
        (d) => d.driver_name === selectedDriver.name
      )?.driver_id;
      const payload = {
        driver_id: driverId,
        amount: selectedDriver.pendingAmount.replace(/[^0-9.]/g, ""),
        charge_from_user: selectedDriver.chargeFromUser.replace(/[^0-9.]/g, ""),
      };
      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && (response.data.success || response.status === 200)) {
        setDrivers((prevDrivers) =>
          prevDrivers.map((driver) =>
            driver.driver_id === driverId
              ? { ...driver, status: "Paid" }
              : driver
          )
        );

        await fetchDrivers(currentPage, searchQuery, "");
        setSuccessMessage("Driver marked as paid successfully!");
        setOpenSuccessSnackbar(true);

        setTimeout(() => {
          handleCloseDriverModal();
        });
      } else {
        throw new Error(response.data.message || "Failed to mark as paid");
      }
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data.message || "Unknown error");
      } else if (error.request) {
        setError(
          "No response from server. Please check your network connection."
        );
      } else {
        setError(error.message);
      }
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validateField = (name: string, value: string) => {
    if (!value) {
      return `${name} is required`;
    }

    if (isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
      return `Please enter a valid positive number`;
    }

    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const fieldMapping: Record<string, keyof PlanData> = {
      rate_per_km: "rate_per_km",
      rate_per_hill_km: "rate_per_hill_km",
      accident_recovery_rate: "accident_recovery_rate",
    };

    SetPlanData((prev) => ({
      ...prev,
      [fieldMapping[name] || name]: value,
      miscellaneous_rate: "0", // Always keep this as "0"
    }));

    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));

    setFormChanged(true);
  };

  const isFormValid = () => {
    const requiredFields = [
      "rate_per_km",
      // "rate_per_hill_km",
      "accident_recovery_rate",
    ];

    return requiredFields.every(
      (field) => PlanData[field as keyof typeof PlanData] && !errors[field]
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowError(false);

    if (isFormValid()) {
      const success = await saveChanges();
      if (success) {
        handleCloseModal();
      }
    } else {
      setShowError(true);
    }
  };

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "90%", marginLeft: "240px", marginTop: "40px" }}
    >
      <Box
        sx={{ p: 3.5, bgcolor: "background.paper", fontFamily: "Montserrat" }}
        color="#2C3E50"
      >
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Driver Payout
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              open={isOpen}
              onClose={() => setIsOpen(false)}
              onOpen={() => setIsOpen(true)}
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              IconComponent={() => (
                <div
                  onClick={handleIconClick}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <ArrowIcon
                    style={{
                      width: "2rem",
                      height: "2rem",
                      marginRight: "8px",
                      fontFamily: "Montserrat",
                      color: "#FDA214",
                      transition: "transform 0.3s ease",
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </div>
              )}
              sx={{
                width: 200,
                height: "3rem",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Custom border color on focus
                  boxShadow: "0 0 0 2px #FDA214", // Custom box shadow on focus
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Optional: set border color on hover
                },
              }}
            >
              <MenuItem value="Paid" style={{ fontFamily: "Montserrat" }}>
                Paid
              </MenuItem>
              <MenuItem value="Pending" style={{ fontFamily: "Montserrat" }}>
                Pending
              </MenuItem>
            </Select>

            <div className="relative flex items-center">
              <SearchIcon
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#FDA214",
                  fontFamily: "Montserrat",
                }} // Adjust size and color if necessary
              />
              <Form.Control
                type="text"
                placeholder="Search Drivers by Name"
                className="pl-10 pr-6 py-2 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "20rem",
                  height: "3rem",
                  fontFamily: "Montserrat",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  textAlign: "left",
                  paddingLeft: "40px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <style>
                {`
                 .relative input::placeholder {
                 color: #B4BDC4; // Placeholder color
                  }
                `}
              </style>
              <Button
                variant="warning"
                className="ml-2 flex items-center justify-center"
                style={{
                  backgroundColor: "#FDA214",
                  color: "#fff",
                  fontFamily: "Montserrat",
                  borderRadius: "10px",
                  height: "3rem",
                  width: "10rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
                onClick={handleOpenModal}
              >
                <Gear size={18} />
                <span style={{ fontFamily: "Montserrat" }}>Payout Rates</span>
              </Button>
            </div>
          </div>
        </div>

        <TableContainer
          component={Paper}
          elevation={0}
          style={{ fontFamily: "Montserrat" }}
        >
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Driver Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Completed Trips
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Total Distance (km)
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Accident Recovery
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Charge from User
                </TableCell>
                {activationStatus === "Paid" ? (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "16px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Amount Paid
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "16px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Pending Amount
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers.map((ticket) => (
                <TableRow
                  key={ticket.driver_id}
                  onClick={() => handleRowClick(ticket)} // Pass the entire ticket object
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    fontFamily: "Montserrat",
                  }}
                >
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.driver_name}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.complete_trip_count}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.total_distance}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.accident_recovery_count}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.charge_from_user}
                  </TableCell>
                  {renderTableCell(ticket)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px", fontFamily: "Montserrat" }}
        >
          {/* <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(filteredDrivers.length / itemsPerPage)}
            onPageChange={handlePageChange}
          /> */}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            fontFamily: "Montserrat",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400, // Increased size
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 6,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              fontFamily: "Montserrat",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5" // Increased font size
              component="h2"
              sx={{ mb: 3, fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Payout Rates
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                fontFamily: "Montserrat",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>

          {showError && !isFormValid() && (
            <Alert severity="error" style={{ fontFamily: "Montserrat" }}>
              <AlertTitle style={{ fontFamily: "Montserrat" }}>
                Error
              </AlertTitle>
              Please fill in all required fields correctly before submitting.
            </Alert>
          )}

          <form className="space-y-6 p-6" onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  label="Rate per Km"
                  name="rate_per_km"
                  value={PlanData.rate_per_km}
                  onChange={handleChange}
                  error={!!errors.ratePerKM}
                  helperText={errors.ratePerExtraKM}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                  sx={{
                    maxWidth: "300px", // Increased width
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "48px", // Increased height
                      fontSize: "1rem", // Increased font size
                      backgroundColor: "#f0f0f0", // Darker background
                      color: "#333", // Darker text color
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#555",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Grid>
              {/* <Grid item>
                <TextField
                  label="Rate per Hilly Km"
                  name="rate_per_hill_km"
                  value={PlanData.rate_per_hill_km}
                  onChange={handleChange}
                  error={!!errors.rateHillyKm}
                  helperText={errors.rateHillyKm}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                  sx={{
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "48px",
                      fontSize: "1rem",
                      backgroundColor: "#f0f0f0",
                      color: "#333",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#555",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Grid> */}
              <Grid item>
                <TextField
                  label="Accident Recovery Rate"
                  name="accident_recovery_rate"
                  value={PlanData.accident_recovery_rate}
                  onChange={handleChange}
                  error={!!errors.accidentRate}
                  helperText={errors.accidentRate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                  sx={{
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "48px",
                      fontSize: "1rem",
                      backgroundColor: "#f0f0f0",
                      color: "#333",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#555",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 8,
                fontFamily: "Montserrat",
              }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  fontFamily: "Montserrat",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "150px",
                }}
                disabled={!isFormValid() || !formChanged}
              >
                Save Changes
              </MuiButton>
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal
        open={!!selectedDriver && activationStatus === "Pending"}
        onClose={handleCloseDriverModal}
        aria-labelledby="driver-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 650,
            bgcolor: "background.paper",
            p: 5,
            borderRadius: 4,
            fontFamily: "Montserrat",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#2C3E50",
              }}
            >
              Pending Amount
            </Typography>
            <MuiButton
              onClick={handleCloseDriverModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 4,
              mb: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  mb: 0,
                  color: "#2C3E50",
                  fontSize: "1.1rem",
                }}
              >
                Amount to be paid
              </Typography>
              <TextField
                value={` ${selectedDriver?.pendingAmount || 6000}`}
                disabled
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#2C3E50",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    height: "15px",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>

            <Box>
              <Typography
                sx={{
                  mb: 0,
                  color: "#2C3E50",
                  fontSize: "1.1rem",
                }}
              >
                Charge from User
              </Typography>
              <TextField
                value={` ${selectedDriver?.chargeFromUser || 500}`}
                disabled
                fullWidth
                sx={{
                  borderRadius: "8px",
                  "& .MuiInputBase-input": {
                    color: "#2C3E50",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    height: "15px",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MuiButton
              onClick={handleMarkAsPaid}
              variant="contained"
              sx={{
                bgcolor: "#FDA214",
                color: "white",
                px: 5,
                py: 1.5,
                mt: 3,
                borderRadius: "6px",
                fontSize: "1rem",
                "&:hover": {
                  bgcolor: "#FB923C",
                },
              }}
            >
              Mark as Paid
            </MuiButton>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSuccessSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" onClose={() => setShowSuccessSnackbar(false)}>
          Payout rates updated successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSuccessSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SupportTicket;
