import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Modal,
  Button,
  TextField,
  styled,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Fill 1.svg";
import { useEffect, useState, useRef, useCallback } from "react";
import { Form } from "react-bootstrap";
import DatabaseApi from "../../../services/database_api";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Pagination from "../PaginationComp/Paginations";

interface TripStatus {
  completed: "success";
  ongoing: "warning";
  upcoming: "info";
}

interface Trip {
  id: number;
  user_name: string;
  driver_name?: string | null;
  pickup_location: string;
  destination: string;
  pickup_time: string | null;
  drop_time?: string | null;
  user_vehicle_type?: string | null;
  status: keyof TripStatus;
  ph_no: string;
  license_plate_number_user?: string | null;
  driver_id?: string | null;
}

interface ApiResponse {
  data: any[];
  current_page: number;
  total_pages: number;
  total: number;
}

export default function TripDetails() {
  const [trips, setTrips] = useState<Trip[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const searchTimeout = useRef<NodeJS.Timeout>();

  const fetchTrips = useCallback(
    async (page: number = 1, search: string = "") => {
      const apiUrl = DatabaseApi.getTripOverViews(page, search);
      const token = localStorage.getItem("authToken");

      try {
        setLoading(true);
        const response = await axios.get<ApiResponse>(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.data?.data) {
          setTrips([]);
          return;
        }

        setTrips(response.data.data);
        setTotalPages(response.data.total_pages);
        setTotalItems(response.data.total);
        setCurrentPage(response.data.current_page);
      } catch (error) {
        console.error("Error fetching trips:", error);
        setTrips([]);
        setTotalPages(1);
      } finally {
        setLoading(false);
      }
    },
    []
  );
  

  const handlePageChange = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);
      fetchTrips(newPage, searchQuery);
    },
    [searchQuery, fetchTrips]
  );

  useEffect(() => {
    fetchTrips(1, "");
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, [fetchTrips]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchQuery(value);

      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }

      searchTimeout.current = setTimeout(() => {
        setCurrentPage(1);
        fetchTrips(1, value);
      }, 600);
    },
    [fetchTrips]
  );

  useEffect(() => {
    fetchTrips(1, "");

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  const handleRowClick = (trip: Trip) => {
    setSelectedTrip(trip);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTrip(null);
  };

  const getStatusColor = (status: keyof TripStatus): string => {
    const colors: Record<keyof TripStatus, string> = {
      completed: "#1FA541",
      ongoing: "#DB5F04",
      upcoming: "#DB5F04",
    };
    return colors[status] || "#ad0e0e";
  };

  const ModalContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "15px",
    padding: "40px",
    right: "50px",
    maxWidth: "750px",
    width: "811px",

    maxHeight: "97vh",
    "&:focus": {
      outline: "none",
    },
  }));

  const InputLabel = styled(Typography)({
    marginBottom: "1px",
    fontWeight: 600,
    fontcolor: "#344054",
    fontFamily: "Montserrat",
    color: "#2C3E50",
  });

  const filteredTrips = trips.filter((trip) => {
    const userName = trip?.user_name?.toLowerCase() || "";
    const phoneNumber = trip?.ph_no || "";
    const searchQueryLower = searchQuery.toLowerCase();

    return (
      userName.includes(searchQueryLower) || phoneNumber.includes(searchQuery)
    );
  });

  const StyledModal = styled(Modal)({
    position: "fixed",
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  });

  const renderTimeFields = (trip: Trip) => {
    return (
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
        <Box sx={{ width: "300px" }}>
          <InputLabel style={{ fontWeight: 600 }}>Pickup Time</InputLabel>
          <TextField
            fullWidth
            value={
              trip.status === ("upcoming" as keyof TripStatus)
                ? "--"
                : trip.pickup_time
                ? new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                    .format(new Date(trip.pickup_time))
                    .replace(/\//g, "-") // Replace slashes with hyphens
                    .replace(/\s(am|pm)$/i, (match) => match.toUpperCase()) // Ensure AM/PM is uppercase
                : "--"
            }
            InputProps={{
              readOnly: true,
              sx: {
                fontFamily: "Montserrat",
                height: "40px",
                borderRadius: "8px",
                fontWeight: 400,
              },
            }}
          />
        </Box>
        <Box sx={{ width: "300px" }}>
          <InputLabel style={{ fontWeight: 600 }}>Drop Time</InputLabel>
          <TextField
            fullWidth
            value={
              trip.status === ("upcoming" as keyof TripStatus) ||
              trip.status === ("ongoing" as keyof TripStatus)
                ? "--"
                : trip.drop_time
                ? new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                    .format(new Date(trip.drop_time))
                    .replace(/\//g, "-") // Replace slashes with hyphens
                    .replace(/\s(am|pm)$/i, (match) => match.toUpperCase()) // Ensure AM/PM is uppercase
                : "--"
            }
            InputProps={{
              readOnly: true,
              sx: {
                fontFamily: "Montserrat",
                height: "40px",
                borderRadius: "8px",
                fontWeight: 400,
              },
            }}
          />
        </Box>
      </Box>
    );
  };

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress size={40} />
      </div>
    );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "237px",
        marginTop: "39px",
        fontFamily: "Montserrat",
      }}
    >
      <Box sx={{ p: 3, maxWidth: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Trip Details
          </Typography>

          <div className="relative ">
            <SearchIcon
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
              style={{
                width: "20px",
                height: "20px",
                color: "#FDA214",
                fontFamily: "Montserrat",
              }}
            />
            <Form.Control
              type="text"
              placeholder="Search by User name/driver name"
              className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                width: "30rem",
                fontFamily: "Montserrat",
                height: "3.5rem",
                lineHeight: "3rem",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
            <style>
              {`
                  .relative input::placeholder {
                  color: #B4BDC4; // Placeholder color
                  }
                 `}
            </style>
          </div>
        </Box>

        <TableContainer
          component={Paper}
          elevation={0}
          style={{ fontFamily: "Montserrat" }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#F0F3FA",
                fontFamily: "Montserrat",
                width: "177px",
                height: "44px",
                padding: "12px",
                gap: "12px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  User Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  Pickup Location
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  Destination
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  Pickup Time
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  Ph. No.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTrips.map((trip, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(trip)}
                  style={{
                    fontFamily: "Montserrat",
                    cursor: "pointer",
                    backgroundColor:
                      hoveredRow === trip.id ? "#f0f0f0" : "transparent",
                  }}
                  onMouseEnter={() => setHoveredRow(trip.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell
                    sx={{
                      fcolor: "#2B3674",
                      fontWeight: 400,
                      Paragraph: "14px",
                      width: "83px",
                      height: "15px",
                      fontSize: "12px",
                      py: 2,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {trip.user_name || "--"}
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 2,
                      fontWeight: 400,
                      Paragraph: "14px",
                      width: "83px",
                      height: "15px",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {trip.pickup_location}
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 2,
                      fontWeight: 400,
                      Paragraph: "14px",
                      width: "83px",
                      height: "15px",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {trip.destination}
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 2,
                      fontWeight: 400,
                      Paragraph: "14px",
                      width: "83px",
                      height: "15px",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {trip.pickup_time
                      ? new Intl.DateTimeFormat("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                          .format(new Date(trip.pickup_time))
                          .replace(/\//g, "-") // Replace slashes with hyphens
                          .replace(/\s(am|pm)$/i, (match) =>
                            match.toUpperCase()
                          ) // Ensure AM/PM is uppercase
                      : "--"}
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 2,
                      fontWeight: 400,
                      Paragraph: "14px",
                      width: "83px",
                      height: "15px",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        color: getStatusColor(trip.status),
                        fontWeight: 700,
                      }}
                    >
                      {trip.status}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 1,
                      fontWeight: 400,
                      Paragraph: "14px",
                      width: "83px",
                      height: "15px",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <Typography
                      component="a"
                      href={`tel:${trip.ph_no}`}
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#93310D",
                        textDecoration: "underline",
                        "&:hover": {
                          color: "#93310D",
                        },
                      }}
                    >
                      {trip.ph_no || "--"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <StyledModal
        open={modalOpen}
        onClose={handleCloseModal}
        disableAutoFocus
        disableEnforceFocus
      >
        <ModalContent>
          <Typography
            variant="h5"
            sx={{ mb: 4, fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Trip Details
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ width: "650px" }}>
              <InputLabel style={{ fontWeight: 600 }}>
                Pickup Location
              </InputLabel>
              <TextField
                fullWidth
                value={selectedTrip?.pickup_location || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    fontFamily: "Montserrat",
                    height: "40px",
                    borderRadius: "8px",
                    fontWeight: 400,
                  },
                }}
                sx={{
                  borderRadius: "40px",
                }}
              />
            </Box>

            <Box sx={{ width: "650px" }}>
              <InputLabel style={{ fontWeight: 600 }}>Drop Location</InputLabel>
              <TextField
                fullWidth
                value={selectedTrip?.destination || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    fontFamily: "Montserrat",
                    height: "40px",
                    borderRadius: "8px",
                    fontWeight: 400,
                  },
                }}
              />
            </Box>

            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
            >
              <Box sx={{ width: "300px" }}>
                <InputLabel style={{ fontWeight: 600 }}>User Name</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.user_name || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "300px" }}>
                <InputLabel style={{ fontWeight: 600 }}>Driver Name</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.driver_name || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
            </Box>

            {selectedTrip && renderTimeFields(selectedTrip)}

            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
            >
              <Box sx={{ width: "300px" }}>
                <InputLabel style={{ fontWeight: 600 }}>
                  User Vehicle Type
                </InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.user_vehicle_type || "--"}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "300px" }}>
                <InputLabel style={{ fontWeight: 600 }}>
                  License Plate Number (User)
                </InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.license_plate_number_user || "--"}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{
                  bgcolor: "#FF8A00",
                  borderRadius: "6px",
                  px: 4,
                  py: 1,
                  fontFamily: "Montserrat",
                  "&:hover": {
                    bgcolor: "#e67a00",
                  },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ModalContent>
      </StyledModal>
      <div
        className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
        style={{ height: "100px" }}
      >
        {totalPages > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}
