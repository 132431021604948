import { useState, useEffect } from "react";
import { ReactComponent as CustomLeftArrow } from "../../../../assets/icons/arrow-circle-left.svg";
import { useParams, Link, useLocation } from "react-router-dom";
import { ReactComponent as Defaulticons } from "../../../../assets/icons/defaultImg.svg";

import {
  Box,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../PaginationComp/Paginations";
import DatabaseApi from "../../../../services/database_api";
import { CircularProgress } from "@mui/material";

interface DriverDetail {
  driver_id: number;
  driver_name: string;
  total_distance: string;
  total_hill_km: string;
  accident_recovery_count: number;
  ravine_ditch_count: number;
  complete_trip_count: number;
  charge_from_user: number;
  driver_total: string;
  amount_Paid: string;
  date_Of_Payment: string;
  pending_Amount: string;
}

interface DashboardData {
  ratings: number;
  total_journeys: number;
  monthly_journeys: Array<{ month: string; count: number }>;
  reviews: number;
  total_earned: number;
  messages: string[];
}

interface PaymentHistory {
  date: string;
  amount: string;
}

const renderAttachment = (attachment: string | null) => {
  if (!attachment) return <Defaulticons className="w-20 h-20 rounded-full" />;

  const fullPath = DatabaseApi.getDriverProfile(attachment);

  if (fullPath.endsWith(".txt")) {
    return (
      <a href={fullPath} target="_blank" rel="noopener noreferrer">
        View Text File
      </a>
    );
  } else {
    return (
      <img
        src={fullPath}
        alt="Profile"
        className="w-20 h-20 rounded-full"
        style={{ maxWidth: "100px", maxHeight: "100px", objectFit: "cover" }}
      />
    );
  }
};
const TICKETS_PER_PAGE = 10;

export default function DriverPayoutDetails() {
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { name, amountPaid, dateOfPayment } = location.state || {};
  const driver = location.state?.driver as DriverDetail;

  useEffect(() => {
    const fetchDashboardData = async () => {
      const driverData = location.state?.driver;
      if (!driverData?.driver_id) {
        setIsLoading(false);
        return;
      }

      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsLoading(false);
        return;
      }
      const apiUrl = DatabaseApi.getDriversdetialsReview();
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            driver_id: driverData.driver_id.toString(),
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if (result.status === "true" && result.data) {
          setDashboardData(result.data);
        } else {
          console.error("Invalid response format:", result);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchDashboardData();
  }, [location.state?.driver]);

  const stats = dashboardData
    ? [
        { label: "Towing Trips", value: dashboardData.total_journeys },
        {
          label: "Total Earned",
          value: `$ ${dashboardData.total_earned.toLocaleString()}`,
        },
        { label: "Average Rating", value: dashboardData.ratings || 0 },
        { label: "Reviews Received", value: dashboardData.reviews },
      ]
    : [];

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const dateOfPaymentArray = Array.isArray(dateOfPayment)
    ? dateOfPayment
    : [{ date: dateOfPayment, amount: amountPaid }]; // Wrap single record in array

  const paginatedTickets: PaymentHistory[] = dateOfPaymentArray.slice(
    (currentPage - 1) * TICKETS_PER_PAGE,
    currentPage * TICKETS_PER_PAGE
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "100%",
        marginLeft: "250px",
        marginTop: "40px",
        fontFamily: "Montserrat",
      }}
    >
      <Box
        sx={{
          p: 3,
          bgcolor: "background.default",
          minHeight: "100vh",
          fontFamily: "Montserrat",
        }}
      >
        <Link
          to="/admin/driver-payouts"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            fontFamily: "Montserrat",
          }}
        >
          <CustomLeftArrow
            style={{
              width: 45,
              height: 55,
              marginRight: 10,
              marginBottom: 10,
              fontFamily: "Montserrat",
            }}
          />
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Driver Payout Details
          </Typography>
        </Link>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
            fontFamily: "Montserrat",
            gap: 2,
          }}
        >
          <Defaulticons className="w-20 h-20 rounded-full " />
          {/* {renderAttachment(driver ?? null)} */}
          <Typography
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            {name}
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4, fontFamily: "Montserrat" }}>
          {stats.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                sx={{
                  p: 2,
                  textAlign: "left",
                  bgcolor: "#F0F3FA",
                  fontFamily: "Montserrat",
                  boxShadow: "none",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="#345C72"
                  gutterBottom
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: "24px",
                  }}
                  color="#345C72"
                  gutterBottom
                >
                  {item.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <TableContainer component={Paper} elevation={0} color="#2C3E50">
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow color="#2C3E50">
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Date of payment
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Amount Paid
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTickets.map((ticket: PaymentHistory, index: number) => (
                <TableRow key={index}>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.date}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Box>
    </div>
  );
}
