import React from "react";
import { ReactComponent as CustomLeftArrow } from "../../../../assets/icons/arrow-circle-left.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/solar_phone-rounded-broken.svg";
import { ReactComponent as VehicleIcon } from "../../../../assets/icons/ph_car-profile.svg";
import { ReactComponent as LicensePlateIcon } from "../../../../assets/icons/vaadin_password.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/solar_letter-broken.svg";
import { ReactComponent as Pdf } from "../../../../assets/icons/Pdf.svg";
import { ReactComponent as Defaulticons } from "../../../../assets/icons/defaultImg.svg";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import DatabaseApi from "../../../../services/database_api";

interface DashboardData {
  ratings: number;
  total_journeys: number;
  monthly_journeys: { month: string; count: number }[];
  reviews: number;
  total_earned: number;
  messages: string[];
}

interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password?: string;
  account_type?: string;
  company_name?: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  license_document?: string | null;
  towing_license?: string | null;
  insurance_document?: string | null;
  upload_company_document?: string | null;
  upload_vehicle_registration?: string | null;
  profile_image?: string | null;
  account_status?: string;
  created_at: string;
  updated_at: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const getDriverDocuments = (driver: Driver) => [
  {
    name: "Driving License",
    file: DatabaseApi.getDriverDoc(driver.license_document || "null") || null,
  },
  {
    name: "Towing License",
    file: DatabaseApi.getDriverDoc(driver.towing_license || "null") || null,
  },
  {
    name: "Insurance Document",
    file: DatabaseApi.getDriverDoc(driver.insurance_document || "null") || null,
  },
  {
    name: "Company Document",
    file:
      DatabaseApi.getDriverDoc(driver.upload_company_document || "null") ||
      null,
  },
  {
    name: "Vehicle Registration",
    file:
      DatabaseApi.getDriverDoc(driver.upload_vehicle_registration || "null") ||
      null,
  },
];

const renderAttachment = (attachment: string | null) => {
  if (!attachment) return <Defaulticons className="w-20 h-20 rounded-full" />;

  const fullPath = DatabaseApi.getDriverProfile(attachment);

  if (fullPath.endsWith(".txt")) {
    return (
      <a href={fullPath} target="_blank" rel="noopener noreferrer">
        View Text File
      </a>
    );
  } else {
    return (
      <img
        src={fullPath}
        alt="Profile"
        className="w-20 h-20 rounded-full"
        style={{ maxWidth: "100px", maxHeight: "100px", objectFit: "cover" }}
      />
    );
  }
};

interface LocationWidgetProps {
  address: string;
  isPickup: boolean;
}

const LocationWidget: React.FC<LocationWidgetProps> = ({
  address,
  isPickup,
}) => (
  <Box
    sx={{
      padding: 2,
      backgroundColor: "#E8ECF4",
      borderRadius: 3,
      display: "flex",
      alignItems: "flex-start", // Changed to flex-start to align dots at the top
      position: "relative",
      fontFamily: "Montserrat",
      minHeight: "80px", // Added minimum height
      maxHeight: "80px", // Added maximum height
    }}
  >
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: isPickup ? "#FF6B00" : "#8B3DFF",
        border: "6px solid white",
        flexShrink: 0,
        position: "relative",
        zIndex: 2,
        mt: 0.5, // Added margin top to align with text
      }}
    />
    <Typography
      sx={{
        marginLeft: 2,
        fontSize: 16,
        color: "#0E2E5D",
        lineHeight: 1.5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontFamily: "Montserrat",
        width: "100%",
        height: "48px", // Exact height for 2 lines with 1.5 line height
      }}
    >
      {address}
    </Typography>
  </Box>
);

interface ConnectedLocationWidgetsProps {
  pickupAddress: string;
  dropoffAddress: string;
}

const ConnectedLocationWidgets: React.FC<ConnectedLocationWidgetsProps> = ({
  pickupAddress,
  dropoffAddress,
}) => {
  return (
    <Box sx={{ position: "relative", fontFamily: "Montserrat" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <LocationWidget address={pickupAddress} isPickup={true} />
        <LocationWidget address={dropoffAddress} isPickup={false} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 26,
          top: 40,
          bottom: 40,
          width: 5,
          background: "linear-gradient(to bottom, #FF6B00, #FB25FF, #8B3DFF)",
          zIndex: 1,
        }}
      />
    </Box>
  );
};

interface TripInfo {
  id: number;
  user_name: string;
  pickup_location: string;
  destination: string;
  pickup_time: string | null;
  created_at: string | null;
  drop_time: string | null;
  user_vehicle_type: string | null;
  license_plate_number_user: string | null;
  duration: string;
  distance: string;
  notes_for_driver: string | null;
  status: string;
}

const trimAddress = (address: string) => {
  const maxLength = 60; // Increased max length since we're controlling height
  if (address.length > maxLength) {
    return `${address.slice(0, maxLength)}...`;
  }
  return address;
};

const TripCard = ({ trip }: { trip: TripInfo }) => (
  <Box
    sx={{
      maxWidth: 345,
      width: "100%",
      height: "100%",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 1,
      overflow: "hidden",
    }}
  >
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          color="#122E59"
          sx={{ 
            fontFamily: "Montserrat",
            fontWeight: "bold"
          }}
        >
          {trip.user_name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {trip.created_at
            ? new Date(trip.created_at)
                .toLocaleString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\//g, "-")
            : "Time not available"}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        gutterBottom
        sx={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "14px",
        }}
      >
        Vehicle: {trip.user_vehicle_type || "N/A"},{" "}
        {trip.license_plate_number_user || "N/A"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          Distance: {trip.distance}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          Duration: {trip.duration}
        </Typography>
      </Box>

      <ConnectedLocationWidgets
        pickupAddress={trimAddress(trip.pickup_location)}
        dropoffAddress={trimAddress(trip.destination)}
      />

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          mt: 2,
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "14px",
        }}
      >
        Notes for driver: {trip.notes_for_driver || "No notes"}
      </Typography>
    </Box>
  </Box>
);

export default function PendingDriver() {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const driver = location.state?.driver as Driver;
  const [trips, setTrips] = useState<TripInfo[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!driver?.id) return;
      const token = localStorage.getItem("authToken");
      const apiUrl = DatabaseApi.getDriversdetialsReview();

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            driver_id: driver.id.toString(),
          }),
        });
        const result = await response.json();
        if (result.status === "true") {
          setDashboardData(result.data);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDashboardData();
  }, [driver?.id]);

  // Updated stats data using API response
  const stats = dashboardData
    ? [
        { label: "Towing Trips", value: dashboardData.total_journeys },
        {
          label: "Total Earned",
          value: `$ ${dashboardData.total_earned.toLocaleString()}`,
        },
        { label: "Average Rating", value: dashboardData.ratings || 0 },
        { label: "Reviews Received", value: dashboardData.reviews },
      ]
    : [];

  // Updated chart data using API response
  const chartData =
    dashboardData?.monthly_journeys.map((item) => ({
      month: item.month.slice(0, 3),
      Journeys: item.count,
    })) || [];

  // Updated reviews using API response
  const reviewsList =
    dashboardData?.messages.map((message) => ({
      rating: "⭐".repeat(dashboardData.ratings),
      text: message,
    })) || [];

  useEffect(() => {
    const fetchTrips = async () => {
      if (!driver?.id) return;

      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          "https://marquelwrecking.com/portal/public/api/get-Driver-Trips",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              driver_id: driver.id.toString(),
            }),
          }
        );

        const result = await response.json();
        if (result.status === "true" && result.data) {
          setTrips(result.data);
        }
      } catch (error) {
        console.error("Error fetching trips:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();
  }, [driver?.id]);

  if (!driver) {
    return <div>No driver data available</div>;
  }

  const handleReject = () => {
    setShowRemoveModal(true);
    handleCloseModal(); // Close the details modal
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };
  // handleDeleteAndBlock
  const handleDeleteAndBlock = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.put(
        DatabaseApi.updateAccountStatusUrl(),
        {
          driver_id: driver.id.toString(),
          account_status: "Deactivate",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Driver blocked successfully");
        // Remove the driver from the list and navigate back
        navigate("/admin/drivers", { state: { removedDriverId: driver.id } });
      } else {
        console.error("Failed to block driver");
      }
    } catch (error) {
      console.error("Error blocking driver:", error);
    }
    setShowRemoveModal(false);
  };
  // handleDelete
  const handleDelete = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        DatabaseApi.deleteDriverUrl(driver.id),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Driver deleted successfully");
        navigate("/admin/drivers", { state: { removedDriverId: driver.id } });
      } else {
        console.error("Failed to delete driver");
      }
    } catch (error) {
      console.error("Error deleting driver:", error);
    }
    setShowRemoveModal(false);
  };

  // pdf view
  const driverDocuments = getDriverDocuments(driver);

  const handleDocumentClick = (fileUrl: string | null) => {
    if (fileUrl) {
      // If the URL is relative, prepend your API base URL
      const fullUrl = fileUrl.startsWith("http")
        ? fileUrl
        : `${process.env.REACT_APP_API_URL}${fileUrl}`;

      // Determine file type
      const fileName = fileUrl.split("/").pop() || "document";
      const fileExtension = fileName.split(".").pop()?.toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

      if (imageExtensions.includes(fileExtension || "")) {
        setSelectedFile(fullUrl);
      } else if (fileExtension === "pdf") {
        window.open(fullUrl, "_blank");
      }
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  return (
    <Box className="bg-white p-8 md:p-20 min-h-screen lg:ml-48 ">
      <div className="p-4 md:p-8 max-w-[100vw] overflow-x-hidden">
        <div className="max-w-7xl mx-auto">
          <Link
            to="/admin/drivers/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomLeftArrow
              style={{
                width: 45,
                height: 55,
                marginRight: 10,
                marginBottom: 10,
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              color="#2C3E50"
              style={{ fontFamily: "Montserrat", fontWeight: "600" }}
            >
              Driver Details
            </Typography>
            <div className="mt-8 flex justify-end space-x-4"></div>
          </Link>
          <div className="flex items-center mb-6 gap-3">
            {renderAttachment(driver.profile_image ?? null)}
            <h2
              className="text-2xl font-semibold"
              style={{ fontFamily: "Montserrat" }}
            >
              {`${driver.first_name} ${driver.last_name}`}
            </h2>

            <div className="ml-auto flex space-x-4">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#961F16",
                  color: "white",
                  fontFamily: "Montserrat",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#7a1612", // Optional: darken the color on hover
                  },
                }}
                onClick={handleReject}
              >
                Remove Driver
              </Button>
            </div>
          </div>

          <Box sx={{ width: "100%", maxWidth: "100%", overflow: "hidden" }}>
            <Grid
              container
              spacing={4}
              sx={{
                width: "103.5%",
                margin: "3",
                padding: "16px 0",
                py: 4,
                fontFamily: "Montserrat",
              }}
            >
              {!isLoading &&
                stats.map((stat, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Paper
                      sx={{
                        p: 3,
                        textAlign: "left", // Aligns content to the left within the Paper
                        backgroundColor: "#f4f8fb",
                        borderRadius: 2,
                        boxShadow: "none", // Removes shadow
                        border: "none", // Ensures no border
                        fontFamily: "Montserrat",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="#345C72"
                        fontWeight="medium"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {stat.label}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="#345C72"
                        fontWeight="bold"
                        component="div"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {stat.value}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </Box>

          <div className="grid grid-cols-3 gap-6 mb-6">
            {/* First Row */}
            <Form.Group className="mb-4 relative">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Phone Number
              </Form.Label>
              <PhoneIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="text"
                value={driver.phone}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>
            <Form.Group className="mb-4 relative custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Email
              </Form.Label>
              <EmailIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="email"
                value={driver.email}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>
            <Form.Group className="mb-4 relative custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Vehicle Type
              </Form.Label>
              <VehicleIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="text"
                value={driver.vehicle_type || "N/A"}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>

            {/* Second Row */}
            <Form.Group className="mb-4 relative custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                License Plate Number
              </Form.Label>
              <LicensePlateIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="text"
                value={driver.license_plate_number || "N/A"}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Account Type
              </Form.Label>
              <Form.Control
                type="text"
                value={driver.account_type || "N/A"}
                readOnly
                className="mt-1"
                style={{ fontFamily: "Montserrat" }}
              />
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Company Name
              </Form.Label>
              <Form.Control
                type="text"
                value={driver.company_name || "N/A"}
                readOnly
                className="mt-1"
                style={{ fontFamily: "Montserrat" }}
              />
            </Form.Group>
          </div>

          <div className="mt-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {driverDocuments.map((doc, index) => (
                <div key={index} className="flex flex-col items-start w-full">
                  <Form.Group className="w-full">
                    <Form.Label
                      className="font-medium"
                      style={{
                        marginBottom: "0.0rem",
                        position: "relative",
                        top: "0.1rem",
                      }}
                    >
                      {doc.name}
                    </Form.Label>
                    <div
                      className="flex items-center border p-4 rounded cursor-pointer w-full bg-gray-100 hover:bg-gray-200 select-none"
                      style={{ height: "80px" }}
                      onClick={() => handleDocumentClick(doc.file)}
                    >
                      {doc.file ? (
                        <>
                          <Pdf className="w-10 h-10 mr-3" />
                          <span className="text-sm">{doc.name}</span>
                        </>
                      ) : (
                        <>
                          <Pdf className="w-10 h-10 mr-3 text-gray-400" />
                          <p className="text-sm text-gray-400">
                            No document uploaded
                          </p>
                        </>
                      )}
                    </div>
                  </Form.Group>
                </div>
              ))}
            </div>

            {selectedFile && (
              <div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
                onClick={clearSelectedFile}
              >
                <div
                  className="max-w-4xl max-h-[80vh] bg-white p-4 rounded-lg"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex justify-end mb-2">
                    <button
                      onClick={clearSelectedFile}
                      className="text-red-500 hover:text-red-700"
                    >
                      Close
                    </button>
                  </div>
                  <img
                    src={selectedFile}
                    alt="Document Preview"
                    className="max-w-full max-h-[70vh] object-contain mx-auto"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="p-6 mb-6 border rounded-lg mt-10">
            <Typography
              variant="h5"
              gutterBottom
              color="#8E8E93"
              textAlign="center"
              style={{ fontFamily: "Montserrat", fontWeight: 600 }}
            >
              Journeys - 2024
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                style={{ fontFamily: "Montserrat" }}
                data={chartData}
                margin={{ top: 20, right: 30, left: 30, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="month" style={{ fontFamily: "Montserrat" }} />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="Journeys"
                  stroke="#F39C12"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  style={{ fontFamily: "Montserrat" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <Box
            sx={{
              maxWidth: "1200px",
              mx: "auto",
              p: 4,
              borderRadius: 10,
              fontFamily: "Montserrat",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 12,
                justifyContent: "space-between",
                fontFamily: "Montserrat",
              }}
            >
              {/* Left Column */}
              <Box
                sx={{
                  flex: 5,
                  border: "1px solid #e0e0e0",
                  borderRadius: 2,
                  padding: 2,
                  margin: -4,
                  fontFamily: "Montserrat",
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  gutterBottom
                  style={{
                    color: "#8E8E93",
                    fontFamily: "Montserrat",
                    fontWeight: 550,
                  }}
                >
                  Reviews
                </Typography>
                {reviewsList
                  .filter((_, index) => index % 2 === 0) // Select reviews for the left column
                  .map((review, index) => (
                    <Card
                      key={index}
                      sx={{
                        mb: 2,
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        fontFamily: "Montserrat",
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="body2"
                          style={{ color: "#0E2E5D", fontFamily: "Montserrat" }}
                        >
                          <div>{review.rating}</div>
                          <p>{review.text}</p>
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
              </Box>

              {/* Right Column */}

              <Box
                sx={{
                  flex: 5,
                  border: "1px solid #e0e0e0",
                  borderRadius: 2,
                  padding: 2,
                  margin: -4,
                  fontFamily: "Montserrat",
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  gutterBottom
                  style={{
                    color: "#8E8E93",
                    fontFamily: "Montserrat",
                    fontWeight: 550,
                  }}
                >
                  Reviews
                </Typography>
                {reviewsList
                  .filter((_, index) => index % 2 !== 0) // Select reviews for the right column
                  .map((review, index) => (
                    <Card
                      key={index}
                      sx={{
                        mb: 2,
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        fontFamily: "Montserrat",
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="body2"
                          style={{ color: "#0E2E5D", fontFamily: "Montserrat" }}
                        >
                          <div>{review.rating}</div>
                          <p>{review.text}</p>
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: 1300,
              margin: "auto",
              p: -4,
              marginTop: 5,
              fontFamily: "Montserrat",
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <Typography>Loading trips...</Typography>
              </Box>
            ) : trips.length > 0 ? (
              <Grid
                container
                spacing={5}
                sx={{ fontFamily: "Montserrat", marginLeft: -2.5 }}
              >
                {trips.map((trip, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={trip.id}
                    style={{ fontFamily: "Montserrat" }}
                  >
                    <TripCard trip={trip} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <Typography>No trips found for this driver</Typography>
              </Box>
            )}
          </Box>

          <Dialog
            open={showRemoveModal}
            onClose={handleCloseRemoveModal}
            aria-labelledby="remove-driver-dialog-title"
            PaperProps={{
              style: {
                borderRadius: "14px",
                padding: "13px",
                maxWidth: "600px",
                fontFamily: "Montserrat",
              },
            }}
          >
            {/* Close Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                fontFamily: "Montserrat",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleCloseRemoveModal}
                sx={{
                  minWidth: "auto",
                  color: "#FDA214",
                  fontWeight: "bold",
                  "&:hover": {
                    bgcolor: "#FB923C",
                    color: "white",
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Modal Title */}
            <DialogTitle
              id="remove-driver-dialog-title"
              style={{
                fontWeight: "bold",
                color: "#122E59",
                textAlign: "center",
                paddingBottom: "8px",
                fontFamily: "Montserrat",
              }}
            >
              Remove Driver
            </DialogTitle>

            {/* Modal Content */}
            <DialogContent>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#122E59",
                  marginBottom: "24px",
                  fontFamily: "Montserrat",
                }}
              >
                Are you sure you want to delete this Driver account?
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#122E59",
                  marginBottom: "16px",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  fontFamily: "Montserrat",
                }}
              >
                The Driver will lose all their data and it will not be
                recoverable.
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#122E59",
                  marginBottom: "24px",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  fontFamily: "Montserrat",
                }}
              >
                To prevent this Driver from creating an account again with the
                same email or phone number, press "Delete & Block."
              </Typography>
            </DialogContent>

            {/* Modal Actions */}
            <DialogActions
              sx={{
                border: "none",
                padding: 0,
                marginTop: "32px",
                justifyContent: "center",
                gap: "16px",
                fontFamily: "Montserrat",
              }}
            >
              {/* Delete & Block Button */}
              <Button
                onClick={handleDeleteAndBlock}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #FDA214",
                  color: "#FDA214",
                  padding: "12px 24px",
                  marginBottom: "30px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  minWidth: "160px",
                  fontFamily: "Montserrat",
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "rgba(253, 162, 20, 0.1)";
                  (e.target as HTMLButtonElement).style.color = "#FDA214";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "transparent";
                }}
              >
                Delete & Block
              </Button>

              {/* Delete Account Button */}
              <Button
                onClick={handleDelete}
                style={{
                  backgroundColor: "#FDA214",
                  border: "none",
                  color: "white",
                  padding: "12px 24px",
                  marginBottom: "30px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  minWidth: "160px",
                  fontFamily: "Montserrat",
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#e89412";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#FDA214";
                }}
              >
                Delete Account
              </Button>

              {/* Cancel Button */}
              <Button
                onClick={handleCloseRemoveModal}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #FDA214",
                  color: "#FDA214",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  marginBottom: "30px",
                  fontWeight: 500,
                  minWidth: "160px",
                  fontFamily: "Montserrat",
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "rgba(253, 162, 20, 0.1)";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "transparent";
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Box>
  );
}
