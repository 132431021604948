import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {getDatabase} from  "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYxZSwkY8dyOlkQm_i6QVBScFG570_s04",
  authDomain: "towingapp-c9f20.firebaseapp.com",
  databaseURL: "https://towingapp-c9f20-default-rtdb.firebaseio.com",
  projectId: "towingapp-c9f20",
  storageBucket: "towingapp-c9f20.firebasestorage.app",
  messagingSenderId: "846022258562",
  appId: "1:846022258562:web:5eabf6cfcfd4b0aaf7299b",
  measurementId: "G-PXQ05W9NL1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, analytics, db, auth, database };