import React from "react";
import { Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  // Validate and normalize props
  const validatedCurrentPage = Math.max(1, Math.min(currentPage, totalPages));
  const validatedTotalPages = Math.max(1, totalPages);

  const hasNextPage = validatedCurrentPage < validatedTotalPages;
  const hasPreviousPage = validatedCurrentPage > 1;

  console.log('Pagination Values:', {
    originalCurrentPage: currentPage,
    originalTotalPages: totalPages,
    validatedCurrentPage,
    validatedTotalPages,
    hasNextPage,
    hasPreviousPage
  });

  const handlePageChange = (newPage: number) => {
    // Validate the new page number before calling onPageChange
    if (newPage >= 1 && newPage <= validatedTotalPages) {
      console.log('Changing to page:', newPage);
      onPageChange(newPage);
    } else {
      console.warn('Invalid page number requested:', newPage);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
      style={{ height: "100px" }}
    >
      <div className="d-flex justify-content-center mt-auto flex-wrap">
        <Button
          variant="outline-secondary"
          size="sm"
          className="mx-1 d-flex align-items-center justify-content-center"
          onClick={() => hasPreviousPage && handlePageChange(validatedCurrentPage - 1)}
          disabled={!hasPreviousPage}
          style={{
            backgroundColor: hasPreviousPage ? "#FDA214" : "transparent",
            color: hasPreviousPage ? "#fff" : "#6c757d",
            borderColor: hasPreviousPage ? "#FDA214" : "#6c757d",
            opacity: hasPreviousPage ? 1 : 0.5,
            cursor: hasPreviousPage ? "pointer" : "not-allowed",
          }}
        >
          <ChevronLeft className="mr-2" size={16} /> Previous
        </Button>

        <span className="mx-3 align-self-center">
          Page {validatedCurrentPage} of {validatedTotalPages}
        </span>

        <Button
          variant="outline-secondary"
          size="sm"
          className="mx-1 d-flex align-items-center justify-content-center"
          onClick={() => hasNextPage && handlePageChange(validatedCurrentPage + 1)}
          disabled={!hasNextPage}
          style={{
            backgroundColor: hasNextPage ? "#FDA214" : "transparent",
            color: hasNextPage ? "#fff" : "#6c757d",
            borderColor: hasNextPage ? "#FDA214" : "#6c757d",
            opacity: hasNextPage ? 1 : 0.5,
            cursor: hasNextPage ? "pointer" : "not-allowed",
          }}
        >
          Next <ChevronRight className="ml-2" size={16} />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;